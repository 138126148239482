import "./src/css/style.css";

const onClientEntry = () => {
  if (typeof window !== "undefined") {
    const darkModeBrowserPreference =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;
    const darkModeUserPreference = localStorage.getItem("dark");
    const gatsbyDiv = document.getElementById("___gatsby");

    if (darkModeUserPreference === "true") {
      gatsbyDiv.classList.add("dark");
    } else if (darkModeUserPreference === "false") {
      gatsbyDiv.classList.add("light");
    } else {
      gatsbyDiv.classList.add(darkModeBrowserPreference ? "dark" : "light");
    }
  }
};

export { onClientEntry };
